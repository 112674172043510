.navigation {
    &--standard {
        background-color: $darkBlue;
        bottom: 0;
        left: 0;
        padding: relSize(10, 16);
        position: fixed;
        top: 0;
        transition: left ease-in 0.2s, width ease-in 0.2s;
        width: relSize(90, 16);
        z-index: 999;

        @include below($br-768) {
            padding: relSize(10, 16) relSize(5, 16);
            width: relSize(40, 16);
        }
    }

    &--footer {
        text-align: right;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li {
            &:not(:last-child) {
                margin-bottom: relSize(10, 16);
            }
        }

        a {
            color: $white;
            display: block;
            font-size: relSize(14, 16);
            line-height: relSize(16, 14);
        }
    }
}
