﻿.breadcrumb {
    padding-right: relSize(20, 16);

    ul {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        margin: 0;
        padding: 0;
        &:not(:last-child) {
            margin-right: relSize(15, 16);
            padding-right: relSize(20, 16);
            position: relative;
            @media all and (max-width: 1024px) {
                margin-right: relSize(10, 16);
                padding-right: relSize(15, 16);
            }
            &::after {
                border-top: 1px solid $lightGreyText;
                border-right: 1px solid $lightGreyText;
                content: "";
                height: relSize(10, 16);
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                width: relSize(10, 16);
                @media all and (max-width: 1024px) {
                    height: relSize(5, 16);
                    width: relSize(5, 16);
                }
            }
        }
    }

    a {
        color: $greyText;
        display: block;
        font-size: relSize(12, 16);
        line-height: relSize(16, 12);
    }
}
