html {
	-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}
*, *:before, *:after {
	-moz-box-sizing: inherit; -webkit-box-sizing: inherit; box-sizing: inherit;
}
html {
	font-size: $baseFontSize;
}
body {
    background-color: $white;
    color: $black;
    font-family: 'Rubik', serif;
    font-size: 1.6em;
    font-weight: 400;
    line-height: ratio(16);
    margin: 0;
    min-height: 100%;
    padding: 0;
    position: relative;
}