.footer {
    &--global {
        align-items: center;
        background-color: $darkestBlue;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: relSize(10, 16) relSize(20, 16) relSize(10, 16) relSize(130, 16);

        @include below($br-768) {
            padding-left: relSize(60, 16);
        }

        > div {
            @include above($br-1024) {
                width: 33.3333333333%;
            }

            @include below($br-1024) {
                width: 50%;
                &:first-child {
                    display: none;
                }
            }
        }
        .domain {
            @include above($br-1024) {
                text-align: center;
            }
            a {
                color: $white;
                display: block;
                font-size: relSize(20, 16);
                font-weight: 700;
                text-transform: uppercase;
            }
        } 
    }
}
