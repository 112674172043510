//PREFIX
////i

.i {
    &__svg {
        display: block;

        svg {
            display: block;
            height: 100%;
            width: 100%;
        }

        &--logo {
            height: relSize(70, 16);
            width: relSize(70, 16);

            @include below($br-768) {
                height: relSize(30, 16);
                width: relSize(30, 16);
            }
        }
    }
}
