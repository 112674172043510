﻿.heading {
    padding-bottom: relSize(50, 16);
    text-align: center;
}

h1, h2, h3, h4, h5, h6 {
	color: $darkBlue;
	font-weight: 700;
    margin-top: 0;
}

h1 {
    font-size: relSize(20, 16);
    line-height: relSize(30, 20);
    margin-bottom: relSize(20, 20);
}

h2 {
    font-size: relSize(18, 16);
    line-height: relSize(25, 18);
    margin-bottom: relSize(20, 18);
    &.sub {
        margin-bottom: relSize(10, 18);
    }
}

h3 {
    font-size: relSize(16, 16);
    line-height: relSize(20, 16);
    margin-bottom: relSize(16, 16);
}

a {
    color: $darkBlue;
    text-decoration: none;
    transition: all ease-in-out 0.2s;
    &:hover, &:focus, &:active {
        outline: none;
        text-decoration: none;
    }
}

.cta {
    font-size: relSize(14, 16);
    font-weight: 600;
    line-height: relSize(20, 14);
    text-transform: uppercase;
}

p {
	margin: 0 0 $ratio#{em} 0;
}

//Lists
ul {
	margin: 0 0 $ratio#{em};
	padding: 0 0 0 1.875em;
	li {
		padding: 0;
	}
	ul {
		margin-bottom: 0;
	}
	&.inline-list {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			display: inline-block;
			margin: 0;
			padding: 0;
		}
	}
    &.nolist {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            margin: 0;
            padding: 0;
        }
    }
}
ol {
    counter-reset: item;
    list-style: none;
    margin: 0 0 $ratio#{em};
	padding: 0 0 0 1.875em;
    li {
        counter-increment: item;
        padding: 0;
        position: relative;
        &::before {
            content: counters(item, ".") ". ";
            font-size: 11px;
            font-weight: 600;
            margin-right: 5px;
        }
    }
    ol {
        margin-bottom: 0;
    }
    &.nolist {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            margin: 0;
            padding: 0;
        }
    }
}