form {
    margin: 0;
    padding: 0;
}
.form {
    &--standard {
        margin: 0 auto;
        padding: 0;
        max-width: relSize(450, 16);
        width: 100%;
    }

    &__row {
        margin-bottom: relSize(30, 16);
        position: relative;
        text-align: left;
        z-index: 1;

        label {
            color: $black;
            display: block;
            font-size: relSize(14, 16);
            line-height: relSize(18, 14);
            margin: 0 0 relSize(5, 16);
            padding: 0;
            text-align: left;
        }

        &.checkbox {
            label {
                align-items: center;
                display: flex;

                input {
                    height: relSize(15, 14);
                    width: relSize(15, 14);
                }

                span {
                    display: block;
                    padding-left: relSize(10, 14);
                }
            }
        }

        &.aside {
            text-align: right;

            > div {
                &:not(:last-child) {
                    margin-bottom: relSize(10, 16);
                }
            }

            a {
                font-size: relSize(14, 16);
                font-weight: 700;
                line-height: relSize(18, 14);
            }
        }

        &.errors {
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
            }

            li {
                color: $red;
                display: block;
                font-size: relSize(14, 16);
                font-weight: 400;
                margin: 0;
                padding: 0 0 0 relSize(20, 14);
                position: relative;

                &::before {
                    background-image: url(/images/icons/error-icon.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    content: "";
                    display: block;
                    height: relSize(14, 14);
                    left: 0;
                    position: absolute;
                    top: relSize(5, 14);
                    width: relSize(14, 14);
                }

                &:not(:last-child) {
                    margin-bottom: relSize(5, 14);
                }
            }
        }

        &.submit {
            text-align: center;
        }
    }
}
input, label, textarea, select {
	display: block;
	width: 100%;
}
input[type="text"], input[type="email"], input[type="tel"], input[type="password"], textarea {
    background-color: transparent;
    border: 1px solid $blue;
    display: block;
    font-size: 16px;
    padding: relSize(10, 16);
    width: 100%;
    &:active, &:focus {
        border-color: $blue;
        outline: none;
    }
}
.validate-error-row {
    color: $red;
    display: block;
    font-size: relSize(14, 16);
    font-weight: 400;
    padding: 0 0 0 relSize(20, 14);
    position: relative;
    text-align: left;

    &::before {
        background-image: url(/images/icons/error-icon.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        content: "";
        display: block;
        height: relSize(14, 14);
        left: 0;
        position: absolute;
        top: relSize(5, 14);
        width: relSize(14, 14);
    }
}