﻿.error {
    border-radius: relSize(5, 16);
    color: $white;
    padding: relSize(20, 16);

    &__name {
        font-weight: 700;
        margin-bottom: relSize(20, 16);
        &.request {
            margin-top: relSize(20, 16);
        }

        p {
            margin: 0;
        }
    }

    &__description {
        font-size: relSize(14, 16);
        line-height: relSize(18, 16);

        p {
            &:last-child {
                margin: 0;
            }
        }
    }

    &--danger {
        background-color: lighten($red, 5%);
    }
}
