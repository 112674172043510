//Global variables used for styles throughout the framework and for various calculations

//PAGE BACKGROUND
$bgcolor: rgb(255, 255, 255);

//CONTENT CONTAINER
$containerPadding: 0 20px;
$totalContainerPadding: 40px;
$maxWidth: 1024px;

//SITEWIDE COLORS
$black: #212D3A;
$blue: #0069B1;
$darkBlue: #26374A;
$darkestBlue: #212D3A;
$lightBlue: #e1effb;
$inactiveBlue: #88a1c4;
$grey: #E0E0E0;
$white: #FFFFFF;
$green: #17e592;
$red: red;
$greyText: #3f3f3f;
$lightGreyText: rgba(63, 63, 63, 0.4);
//FONT
$mainFontFamily: 'Rubik', serif;
//BASE FONT SIZE
$baseFontSize : 62.5%;
$maxBodyFont: 1.6em;
$minBodyFont: 1.4em;
$fontSizePx: 16;
////TITLE FONT SIZE
$maxHeadSize: 39.81312;
$diff: 0.8333341;

//GOLDEN RATIO
////Used for working out optimal line-height and line-width throughout
$ratio: 1.61803399;

//GENERIC color
$errorcolor: $red;
$successcolor: $green;

//LAYOUT
////Can be maximum 24
$l__colNumber: 12;

@import "_mixins.scss";
