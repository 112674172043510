.btn {
    border: 0;
    border-radius: relSize(5, 16);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: inline-block;
    font-size: relSize(16, 16);
    font-weight: 700;
    line-height: 1em;
    padding: relSize(12, 16) relSize(30, 16);
    position: relative;
    ;
    transition: all ease-in 0.2s;

    @include above($br-768) {
        min-width: relSize(250, 16);
    }

    &--standard {
        background-color: $darkBlue;
        color: $white;

        &::after {
            border-right: 1px solid $white;
            border-top: 1px solid $white;
            content: "";
            height: relSize(4, 16);
            position: absolute;
            right: relSize(15, 16);
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            transition: all ease-in 0.2s;
            width: relSize(4, 16);
        }

        &:hover {
            background-color: darken($darkBlue, 10%);
            color: darken($darkBlue, 10%);

            &::after {
                transform: translateY(-50%) translateX(5px) rotate(45deg);
            }
        }
    }
}
