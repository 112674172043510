.header {
    &--global {
        align-items: center;
        background-color: $grey;
        box-shadow: 0 5px 5px rgba(0,0,0,0.3);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: relSize(8, 16) relSize(20, 16) relSize(8, 16) relSize(60, 16);

        @include above($br-768) {
            padding-left: relSize(130, 16);
            transition: padding ease-in 0.2s
        }
    }
}
